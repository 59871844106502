import React from 'react'
import './PosterDescriptPic.css'
import useViewportSize from './useViewportSize'
import { Link } from 'react-router-dom'

const PosterDescriptPic = ({ content = { title: '', descript: '', link: '' }, img, link, reverse = false }) => {

    const viewportSize = useViewportSize()

    return (
        <div className="poster-descript-pic">
            {!reverse ? <>
                {viewportSize.width < 841 ?
                    <>
                        <img className='poster-pic' src={img} alt={content?.title} />
                        <p className='poster-descript'>
                            {content?.title || 'TITLE'}
                            <br />
                            {content?.descript || 'DESCRIPTION'}
                            <br />
                            {content?.link && <Link className={'link'} to={content.link}>DESCUBRIR</Link>}
                        </p>
                    </> : <><p className='poster-descript'>
                        {content?.title + 'ne' || 'TITLE'}
                        <br />
                        {content?.descript || 'DESCRIPTION'}
                        <br />
                        {content?.link && <Link className={'link'} to={content.link}>DESCUBRIR</Link>}
                    </p>
                        <img className='poster-pic' src={img} alt={content?.title} /></>}
            </> :
                <>
                    <img className='poster-pic' src={img} alt={content?.title} />
                    <p className='poster-descript'>
                        {content?.title || 'TITLE'}
                        <br />
                        {content?.descript || 'DESCRIPTION'}
                        <br />
                        {content?.link && <Link className={'link'} to={content.link}>DESCUBRIR</Link>}
                    </p>
                </>
            }
        </div>
    )
}

export default PosterDescriptPic