import React from 'react'
import ImageTitle from '../comps/ImageTitle'
import PosterDescriptPic from "../comps/PosterDescriptPic";
import './Comestic.css'
import Carousel from 'react-multi-carousel';
import SlideCard from '../comps/SlideCard';
import DoublePic from '../comps/DoublePic'
import Moreinmenu from '../comps/Moreinmenu';

const TITLE1 = "https://live.staticflickr.com/65535/50632589102_ec2ffb2fa4_b.jpg"
const BASE1 = 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/base07.jpg'
const SOMBRA1 = 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fsombra60.jpg'

const Comestic = () => {
    return (
        <div className='Comestic'>
            <ImageTitle
                title={'Cosméticos'}
                source={TITLE1}
                titleColor={'black'}
            />
            <PosterDescriptPic
                img={BASE1}
                content={{
                    title: 'Base',
                    descript: '',
                    link: 'hello'
                }}
            />
            {/*             <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={2}
                swipeable
            >
                {BASECARDS.map(({title, img}, key) =>
                    <SlideCard
                        title={title}
                        img={img}
                    />
                )}
            </Carousel> */}
            <PosterDescriptPic
                img={SOMBRA1}
                content={{
                    title: 'Sombra',
                    descript: '',
                    link: 'hello'
                }}
                reverse
            />
            {/*             <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={2}
                swipeable
            >
                {SOMBRACARDS.map(({title, img}, key) =>
                    <SlideCard
                        title={title}
                        img={img}
                    />
                )}
            </Carousel> */}
            <DoublePic
                prod1={{
                    title: 'PESTAÑA',
                    img: 'https://get.pxhere.com/photo/woman-view-red-human-close-eyebrow-make-up-eyelash-close-up-human-body-face-nose-cheek-eye-head-skin-beauty-organ-mascara-melted-cosmetics-eyelashes-forehead-eyelash-extensions-898629.jpg',
                    link: '/c/cosm%C3%A9ticos/Pesta%C3%B1as?page=1'
                }}
                prod2={{
                    title: 'HERRAMIENTA',
                    img: 'https://live.staticflickr.com/65535/50569708677_9f1aa1220d_b.jpg',
                    link: '/c/cosm%C3%A9tico/Herramienta?page=1'
                }}
            />
            <DoublePic
                prod1={{
                    title: 'BROCHAS Y ESPONJAS',
                    img: 'https://images.pexels.com/photos/7712475/pexels-photo-7712475.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                    link: '/c/cosméticos/Brochas%20y%20Esponjas?page=1'
                }}
                prod2={{
                    title: 'DESMAQUILLANTES',
                    img: 'https://live.staticflickr.com/65535/49671501507_32a6fa5ebf_b.jpg',
                    link: '/c/cosméticos/Brochas%20y%20Esponjas?page=1'
                }}
            />
            <Moreinmenu />
        </div>
    )
}

const BASECARDS = [
    {
        title: 'COMFOR BEAUTY - BASE Y PRIMER NO.CX6071',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/base01.jpg'
    },
    {
        title: 'COMFOR BEAUTY - BASE NO.CX6070',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/base02.jpg'
    },
    {
        title: 'FIT ME! - BASE NO.CY133',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/base05.jpg'
    },
    {
        title: 'FIT ME! - BASE NO.0830',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/base08.jpg'
    }
]
const SOMBRACARDS = [
    {
        title: 'DODO GIRL - EYE PARTNERBEST NO.D3256',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/sombra01.jpg'
    },
    {
        title: 'HUDABEAUTY - BEAUTY KILLER NO.H70164',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/sombra32.jpg'
    },
    {
        title: 'HUDABEAUTY - CHARMING MOCHA NO.H70158',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/sombra26.jpg'
    },
    {
        title: 'FABBLA - PASSION NO.ES3027',
        img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/sombra50.jpg'
    }
]

export default Comestic