import axios from './axios.js'

export const getCategories = () => axios.get('/categories')

export const getCategoryProducts = (category, page = '', limit = '') => axios.get(
    '/categories/' + category + '?page=' + page + '&limit=' + limit
)

export const getCategoryProductsCount = (category) => axios.get(
    '/categories/c/' + category
)

export const updateCategory = (newCategory) => axios.post('/categories/m/update', newCategory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})

export const createCategory = (newCategory) => axios.post('/categories/m/create', newCategory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})

export const deleteCategory = (newCategory) => axios.post('/categories/m/delete', newCategory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})