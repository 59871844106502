import React from 'react'
import './ThreeItems.css'
import { Link } from 'react-router-dom'

const ThreeItems = ({ title, link, img1, img2, img3, p1, p2, p3, l1, l2, l3 }) => {
    function scrollToTop() {
        document.querySelector('.Home')
            .scroll({ top: 0, left: 0, behavior: 'smooth' })
    }

    return (
        <div className='ThreeItems'>
            {(title || link) && <div className='titlebar'>
                <h2>{title || 'missing title'}</h2>
                <Link to={link} className='titlelink' onClick={scrollToTop}>
                    Más →</Link>
            </div>}
            <ul>
                <li className="item1">
                    <img src={img1} alt="img1" />
                    <p>{p1 || 'p1'}</p>
                    <Link to={l1} className='itemlink' onClick={scrollToTop}>
                        Más informacion</Link>
                </li>
                <li className="item2">
                    <img src={img2} alt="img2" />
                    <p>{p2 || 'p2'}</p>
                    <Link to={l2} className='itemlink' onClick={scrollToTop}>
                        Más informacion</Link>
                </li>
                <li className="item3">
                    <img src={img3} alt="img2" />
                    <p>{p3 || 'p3'}</p>
                    <Link to={l3} className='itemlink' onClick={() =>
                        document.querySelector('.Home')
                            .scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                        Más informacion</Link>
                </li>
            </ul>
        </div>
    )
}

export default ThreeItems