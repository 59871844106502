import React from 'react'

const ReloadImage = ({ productName, className, imgurl }) => {

    const [show, setShow] = React.useState(false)
    const [isHover, setIsHover] = React.useState(false)

    React.useEffect(() => {
        setShow(false)
    }, [imgurl])

    return (
        <>
            <div
                className={className}
                style={{
                    display: show? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: !show ? '#EEEEEE' : 'transparent',
                    height: !show? '100px' : '0px',
                }}
            >
                Anny Selection <br/> Cargando...
            </div>
            <img
                className={className}
                src={imgurl}
                alt={productName}
                onLoad={() => setShow(true)}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                style={{
                    borderBottom: isHover ? '3.5px solid grey' : '',
                    display: show ? 'block' : 'none'
                }}
            />
        </>
    )
}

export default ReloadImage