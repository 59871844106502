import React from 'react'
import ImageTitle from '../comps/ImageTitle'
import Moreinmenu from '../comps/Moreinmenu'
import PosterDescriptPic from '../comps/PosterDescriptPic'
import ThreeItems from '../comps/ThreeItems'
import './Infatil.css'

const Infatil = () => {
    return (
        <div className='Infatil'>
            <ImageTitle
                title='Infatil'
                source='https://smartwhale.nyc3.digitaloceanspaces.com/products/kidtoy/infatiltitleimg01.jpg'
            />
            <PosterDescriptPic
                content={{
                    title: 'STAND BY ME - SET TEAM LIGHTNING MCQUEEN 95 NO.YOYO-8001',
                    descript: 'Materiales escolares',
                    link: './Escolar/STAND%20BY%20ME%20-%20SET%20TEAM%20LIGHTNING%20MCQUEEN%2095%20NO.YOYO-8001'
                }}
                img='https://smartwhale.nyc3.digitaloceanspaces.com/products/kidtoy/escolar01.jpg'
            />
            <ThreeItems
                title='Escolar'
                link='./Escolar?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/kidtoy/escolar03.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/kidtoy/escolar29.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/kidtoy/escolar30.jpg'
                p1='STAND BY ME - SET DORAEMON NO.YOYO-8001'
                p2='STAND BY ME - SET MY LITTLE PONY NO.YOYO8003-A'
                p3='TAND BY ME - SET MICKEY MOUSE NO.YOYO8002A'
                l1='./Escolar/STAND%20BY%20ME%20-%20SET%20DORAEMON%20NO.YOYO-8001'
                l2='./Escolar/STAND%20BY%20ME%20-%20SET%20FRONZEN%20FEVER%20NO.YOYO-8001'
                l3='./Escolar/STAND%20BY%20ME%20-%20SET%20MICKEY%20MOUSE%20NO.YOYO8002A'
            />
            <Moreinmenu />
        </div>
    )

}

export default Infatil