import React from 'react'
import './Unas.css'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css"
import ImageTitle from '../comps/ImageTitle'
import SlideCard from '../comps/SlideCard'
import ThreeItems from '../comps/ThreeItems'
import Moreinmenu from '../comps/Moreinmenu'

const Unas = () => {

  const titleSource = 'https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/ad04small.jpg'
  return (
    <div className='Unas'>
      <ImageTitle source={titleSource} title='UÑA' titleColor='white' />
      <ThreeItems
        title='Gel & Shellac'
        link='./Pintura%20de%20Gel%20&%20Shellac?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/cateyegel02.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/nailart01.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/gelsequins01.jpg'
        p1='ANNY SELECTION - GEL OJOS DE GATO'
        p2='ANNY SELECTION - SET GEL PARA ARTE'
        p3='ANNY SELECTION - GEL BRILLO BRILLANTES'
        l1='./Gel%20&%20Shellac/ANNY%20SELECTION%20-%20GEL%20OJOS%20DE%20GATO'
        l2='./Gel%20&%20Shellac/ANNY%20SELECTION%20-%20SET%20GEL%20PARA%20ARTE'
        l3='./Gel%20&%20Shellac/ANNY%20SELECTION%20-%20GEL%20BRILLO%20BRILLANTES'
      />
      <ThreeItems
        title='Stickers'
        link='./Stickers?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/sticker00.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/sticker08.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/sticker18.jpg'
        p1='ANNY SELECTION - PRESENTACIÓN DE STICKER'
        p2='ANNY SELECTION - STICKER PATRÓN'
        p3='ANNY SELECTION - STICKER VARIADO NO.2'
        l1='./Stickers/ANNY%20SELECTION%20-%20PRESENTACIÓN%20DE%20STICKER'
        l2='./Stickers/ANNY%20SELECTION%20-%20STICKER%20PATRÓN'
        l3='./Stickers/ANNY%20SELECTION%20-%20STICKER%20VARIADO%20NO.2'
      />
      <ThreeItems
        title='Accesorios Para Uña'
        link='./Accesorios%20Para%20Uña?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/accessory05.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/nailcart01.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/unas/accessory68.jpg'
        p1='ANNY SELECTION - PERLAS SINFONIA SIRENA NO.4'
        p2='ANNY SELECTION - PAQUETE DE ACCESORIO PARA UÑAS NO.1'
        p3='ANNY SELECTION - LENTEJUELAS PARA DECORAR UÑAS UNO.1'
        l1='./Accesorios%20Para%20Uña/LYZ%20-%2004%20MERMAID%20SYMPHONY%20BEADS'
        l2='./Accesorios%20Para%20Uña/ANNY%20SELECTION%20-%20PAQUETE%20DE%20ACCESORIO%20PARA%20UÑAS%20NO.1'
        l3='./Accesorios%20Para%20Uña/ANNY%20SELECTION%20-%20LENTEJUELAS%20PARA%20DECORAR%20UÑAS%20UNO.1'
      />
      <ThreeItems
        title='Lampara & Drills'
        link='./Lamparas%20&%20Drills?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fdrill04.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fdrill07.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fdrill08.jpg'
        p1='DRILL ROSADO'
        p2='PLSG - DRILL RECARGABLE'
        p3='DRILL 4 EN 1 NO.BQ-608'
        l1='./Lamparas%20&%20Drills/DRILL%20ROSADO'
        l2='./Lamparas%20&%20Drills/PLSG%20-%20DRILL%20RECARGABLE'
        l3='./Lamparas%20&%20Drills/DRILL%204%20EN%201%20NO.BQ-608'
      />
      <Moreinmenu />
    </div>
  )
}

export default Unas