import React from 'react'
import "./Main.css"
import { Link } from 'react-router-dom'

import landing3 from '../assets/landing3.jpg'
import landing8 from '../assets/landing8.jpg'
import arrowright from '../assets/arrowright.svg'

const PERFUMEAD1 = 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fperfume%2Fadperfume01.webp'
const LANDING1 = 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fa.jpg'
const LANDING7 = 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Faccessories%2Faccessorymainimg.jpg'

const Main = () => {
    return (
        <div className='main'>
            <div className="cards">
                <div className="card">
                    {/* unas */}
                    <img src={landing3} alt="landing img" />
                    <h2 className='title'>UÑAS</h2>
                    <div className="hook down">
                        <div className="bg">
                            <Link className='link black-text' to='/c/Uña'>
                                Descubrir más →
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card">
                    {/* comesticos */}
                    <img src={landing8} alt="landing img" />
                    <h2 className='title'>COSMÉTICO</h2>
                    <div className="hook down">
                        <div className="bg">
                            <Link className='link black-text' to='/c/Cosméticos'>
                                Descubrir más →
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <img src={'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbody250ml%2Fadbody01.webp'} alt="landing img" />
                    <h2 className='title'>BODY</h2>
                    <div className="hook down">
                        <div className="bg">
                            <Link className='link black-text' to='/c/Body'>
                                Descubrir más →
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <img src={PERFUMEAD1} alt="landing img" />
                    <h2 className='title'>PERFUME</h2>
                    <div className="hook down">
                        <div className="bg">
                            <Link className='link black-text' to='/c/Perfumes'>
                                Descubrir más →
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cards">
                <div className="card half">
                    <img className={'border-bot'} alt="landing img"
                        src={LANDING1} />
                    <p className='norm-hook pos-bottom-10 halfbox-title'>Skin Care</p>
                    <Link className='norm-hook black-text' to='/c/Skin Care'
                    >
                        <p>Descubrir más</p><img src={arrowright} alt="arrow right" />
                    </Link>
                </div>
                <div className="card half">
                    <img className={'border-bot'}
                        src={'https://www.publicdomainpictures.net/pictures/270000/velka/creamskin-care-cosmetics-lid-f.jpg'} alt="landing img" />
                    <p className='norm-hook pos-bottom-10 halfbox-title'>Crema</p>
                    <Link className='norm-hook black-text' to='/c/Crema/Crema 236mL?page=1'>
                        <p>Descubrir más</p><img src={arrowright} alt="arrow right" />
                    </Link>
                </div>
                <div className="card half">
                    <img className={'border-bot'} src={LANDING7} alt="landing img" />
                    <p className='norm-hook pos-bottom-10 halfbox-title'>Accesorios</p>
                    <Link className='norm-hook black-text' to='/c/Accesorios'>
                        <p>Descubrir más</p><img src={arrowright} alt="arrow right" />
                    </Link>
                </div>
                <div className="card half">
                    <img className={'border-bot'}
                        src={'https://c0.wallpaperflare.com/preview/824/602/552/school-times-school-school-supplies-brushes-crayon.jpg'}
                        alt="landing img" />
                    <p className='norm-hook pos-bottom-10 halfbox-title'>Escolar</p>
                    <Link className='norm-hook black-text' to='./c/Infatil/Escolar?page=1'>
                        <p>Descubrir más</p><img src={arrowright} alt="arrow right" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Main