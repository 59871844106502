import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.css";

import Home from './routes/Home';
import Error from './routes/Error';
import Products from './routes/Products';
import Perfumes from './routes/Perfumes';
import Main from './routes/Main';
import Dashboard from './routes/Dashboard';
import Product from './comps/Product';
import Unas from './routes/Unas';
import Comestic from './routes/Comestic';
import Body from './routes/Body';
import Infatil from './routes/Infatil';
import SkinCare from './comps/SkinCare';
import Accesorios from './routes/Accesorios';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
    children: [
      {
        path: "/c/:category/:type/:product",
        loader: ({ params }) => params,
        element: <Product />
      },
      {
        path: "/c/:category/:subcategory",
        loader: ({ params }) => params,
        element: <Products />
      },
      {
        path: "/c/:category",
        loader: ({ params }) => params,
        element: <Products />,
      },
      {
        path: "/c/Perfumes",
        element: <Perfumes /> //custome perfumes page
      },
      {
        path: "/c/Uña",
        element: <Unas /> //need custom unas page
      },
      {
        path: "/c/Cosméticos",
        element: <Comestic /> //need custom unas page
      },
      {
        path: "/c/Body",
        element: <Body /> //need custom unas page
      },
      {
        path: '/c/Infatil',
        element: <Infatil />
      },
      {
        path: '/c/Skin Care',
        element: <SkinCare />
      },
      {
        path: '/c/Accesorios',
        element: <Accesorios />
      },
      {
        index: true,
        element: <Main /> //default
      }
    ]
  }/* ,
  {
    path: "/donny",
    element: <Dashboard />,
    errorElement: <Error />,
  } */
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
