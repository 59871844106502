import React from 'react'
import ImageTitle from '../comps/ImageTitle'
import './Body.css'
import ThreeItems from '../comps/ThreeItems'
import Moreinmenu from '../comps/Moreinmenu'

const BODYIMG = 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbody250ml%2Fadbody01.webp'

const Body = () => {
    return (
        <div className='Body'>
            <ImageTitle
                title='Body'
                source={BODYIMG}
                titleSize='24'
            />
            <ThreeItems
                title='Body 85mL'
                link='./Body%2085mL?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfume15.JPG'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfume26.jpg'
                img3=''
                p1={`ANNY SELECTION - SEA'S FREE`}
                p2='V.V LOVE - NOBLE BERRY'
                p3='ONE MORE'
                l1={`./Body%2085mL/V.V%20LOVE%20-%20FRUTAS`}
                l2='./Body%2085mL/V.V%20LOVE%20-%20%20%20SENTIMIENTOS'
                l3='./Body%20250mL/V.V%20LOVE%20-%20LUSH%20PETALS'
            />
            <ThreeItems
                title='Body 250mL'
                link='./Body%20250mL?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/body250ml/body03.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/body250ml/bodyshort03.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/body250ml/body21.jpg'
                p1={`ANNY SELECTION - SEA'S FREE`}
                p2='V.V LOVE - NOBLE BERRY'
                p3='V.V LOVE - LUSH PETALS'
                l1={`./Body%20250mL/ANNY%20SELECTION%20-%20SEA'S%20FREE`}
                l2='./Body%20250mL/V.V%20LOVE%20-%20NOBLE%20BERRY'
                l3='./Body%20250mL/V.V%20LOVE%20-%20LUSH%20PETALS'
            />
            <ThreeItems
                title='Set Body & Crema'
                link='./Set%20Body%20&%20Crema?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbodyset%2Fcb04.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/bodyset/smallbc15.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/bodyset/smallbc18.jpg'
                p1={`V.V LOVE - SET GRANDE PINK SUMMER SUN CHARMING NO.VL9060-11`}
                p2='V.V LOVE - SET PEQUEÑA COCONUT FREEZE NO.VL9059-42'
                p3='V.V LOVE - SET PEQUEÑA LOVELY DREAM NO.VL9059-52'
                l1={`./Set%20Body%20&%20Crema/V.V%20LOVE%20-%20SET%20GRANDE%20PINK%20SUMMER%20SUN%20CHARMING%20NO.VL9060-11`}
                l2='./Set%20Body%20&%20Crema/V.V%20LOVE%20-%20SET%20PEQUEÑA%20COCONUT%20FREEZE%20NO.VL9059-42'
                l3='./Set%20Body%20&%20Crema/V.V%20LOVE%20-%20SET%20PEQUEÑA%20LOVELY%20DREAM%20NO.VL9059-52'
            />
            <ThreeItems
                title='Set Body & Perfume & Gel de Ducha'
                link='./Set%20Body%20&%20Perfume%20&%20Gel%20de%20Ducha?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbodyset%2Fbodygel04.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbodyset%2Fcbpset03.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fbodyset%2Fcbpset19.jpg'
                p1={`V.V LOVE - SET MINI COURAGEOUS NO.VL9059-60`}
                p2='V.V LOVE - SET GRANDE ROYAL SWEETY NO.VL5042'
                p3='V.V LOVE - SET GRANDE PINK NO.VL5018'
                l1={`./Set%20Body%20&%20Perfume%20&%20Gel%20de%20Ducha/V.V%20LOVE%20-%20SET%20MINI%20COURAGEOUS%20NO.VL9059-60`}
                l2='./Set%20Body%20&%20Perfume%20&%20Gel%20de%20Ducha/V.V%20LOVE%20-%20SET%20GRANDE%20ROYAL%20SWEETY%20NO.VL5042'
                l3='./Set%20Body%20&%20Perfume%20&%20Gel%20de%20Ducha/V.V%20LOVE%20-%20SET%20GRANDE%20PINK%20NO.VL5018'
            />
            <Moreinmenu />
        </div>
    )
}

export default Body