import React from 'react'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import ProdsDisplay from '../comps/ProdsDisplay'
import { getCategoryProducts, getCategoryProductsCount } from '../api/categoriesAPIs'
import './Products.css'
import { getSubcategoryFilteredProductsCount, getSubcategoryProducts, getSubcategoryProductsCount } from '../api/prorductsAPIs'
import { getSubcategoryKeywords } from '../api/subcategoriesAPIs'

const Products = () => {
    const params = useLoaderData()
    const [products, setProducts] = React.useState(null)
    const [totalProducts, setTotalProducts] = React.useState(0)
    const [keywords, setKeywords] = React.useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const handleFilteredProdCounts = React.useCallback(getSubcategoryFilteredProductsCount,
        [params.subcategory, searchParams.get('query')])

    //fetch whatever inside params
    React.useEffect(() => {
        const fetchCategoryProducts = async () => {
            const result = await getCategoryProducts(
                params.category,
                searchParams.get('page') || '',
            )
            const counts = await getCategoryProductsCount(params.category)
            setProducts(result.data)
            setTotalProducts(counts.data)
        }
        const fetchSubcategoryProducts = async () => {
            const result = await getSubcategoryProducts(
                params.subcategory,
                searchParams.get('page') || '',
                searchParams.get('query') || ''
            )
            let count
            if (searchParams.get('query'))
                count = await getSubcategoryFilteredProductsCount(
                    params.subcategory, searchParams.get('query')
                )
            else
                count = await getSubcategoryProductsCount(params.subcategory)
            const loadedkeywords = await getSubcategoryKeywords(params.subcategory)
            setProducts(result.data)
            setTotalProducts(count.data)
            setKeywords(loadedkeywords.data)
        }
        if (params.subcategory) fetchSubcategoryProducts()
        else fetchCategoryProducts()
    }, [params, searchParams])


    return (
        <div className='products'>
            <ProdsDisplay
                data={products}
                count={totalProducts}
                currentPage={searchParams.get('page')}
                keywords={keywords}
                subcat={params.subcategory}
                getCounts={handleFilteredProdCounts}
                navigation={navigate}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
        </div>
    )
}

export default Products