import React from 'react'
import "./ImageTitle.css"

const ImageTitle = ({ type, source, title, titleColor, titleSize }) => {
    return (
        <div className='ImageTitle'>
            <img className='img' src={source} alt={title} />
            <h2 className='title' style={{ color: titleColor, fontSize: titleSize }}>
                {title || 'TITLE'}
            </h2>
        </div>
    )
}

export default ImageTitle