import axios from './axios.js'

export const getProducts = (nameonly) => nameonly ? axios.get('/products/' + nameonly) : axios.get('/products')
export const updateProduct = (product) =>
    axios.post('/products/m/update', product, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })

export const deleteProduct = (product) =>
    axios.post('/products/m/delete', product, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
export const createProduct = (product) => axios.post('/products/m/create', product, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})
export const getSubcategoryProducts = (subcategory, page, query) => axios.get(
    '/products/s/' + subcategory + '?page=' + page + '&query=' + query
)

export const getSubcategoryProductsCount = (subcategory) => axios.get('/products/sc/' + subcategory)

export const getSubcategoryFilteredProductsCount = (subcategory, query) => axios.get('/products/sfc/' + subcategory + "?query=" + query)
