import axios from 'axios'

const myaxios = axios.create({
    /* baseURL: process.env.REACT_APP_BASEURL, */
    baseURL: 'https://www.annyselection.com/api/',
    headers: {
        'Content-Type': 'application/json'
    },
    /* withCredentials: true */
}) 

export default myaxios