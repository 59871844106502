import React from 'react'
import "./Home.css"
import { Outlet, Link, useLocation } from 'react-router-dom'
import Navbar from '../comps/Navbar'
import { getCategories } from '../api/categoriesAPIs'
import ScrollToTop from '../comps/ScrollToTop'

const Home = () => {

    const [categories, setCategories] = React.useState(null)
    const { pathname } = useLocation()

    React.useEffect(() => {
        const fetchCategories = async () => {
            const res = await getCategories()
            setCategories(res.data)
        }
        fetchCategories()
    }, [])

    React.useLayoutEffect(()=>{
        let pathbe = decodeURI(pathname)
        if(decodeURI(pathname) === '/') pathbe = ''
        document.title= "Anny Selection " + pathbe
    },[pathname])

    return (
        <div className='Home'>
            <Navbar />
            <Outlet />
            <ScrollToTop/>
            <footer>
                <p className="footer-title">ANNY SELECTION</p>
                <div className="lists">
                    <ul>
                        <li className='txt-white'>EXPLORE ANNY SELECTION</li>
                        {categories && categories.map((category, key) =>
                            <li key={key}>
                                <Link to={'/c/' + category?.categoryName}>
                                    {category.categoryName}
                                </Link>
                            </li>
                        )}
                    </ul>
                    {/* <ul>
                        <li className='txt-white'>CONTACTO</li>
                        <li>WhatsApp +505 7538 2616</li>
                        <li><a href="https://www.facebook.com/profile.php?id=100063449546708">Facebook</a></li>
                        <li><a href="https://www.instagram.com/comercial__anny/?hl=en">Instagram</a></li>
                    </ul> */}
                    <ul>
                        <li className='txt-white'>HORAS</li>
                        <li>De Lunes a Sábado</li>
                        <li>8:00 AM ~ 5:00 PM</li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Home