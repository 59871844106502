import React from 'react'
import DoublePic from './DoublePic'
import ImageTitle from './ImageTitle'
import './SkinCare.css'
import ThreeItems from './ThreeItems'
const SkinCare = () => {
    return (
        <div className='SkinCare'>
            <ImageTitle
                title='Skin Care'
                source='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fa.jpg'
            />
            <ThreeItems
                title='Hidrantente de Labios'
                link='./Hidratante%20de%20Labios?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fvaseina01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fvaseina08.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fcomestico%2Fvaseina12.jpg'
                p1='VASEINA - BÁLSAMO LABIAL EMOJI NO.8013-2'
                p2='COMFOR BEAUTY - BÁLSAMO LABIAL FRUTAS NO.CX5053'
                p3='VASEINA - ALOE VERA'
                l1='./Hidratante%20de%20Labios/VASEINA%20-%20BÁLSAMO%20LABIAL%20EMOJI%20%20NO.8013-2'
                l2='./Hidratante%20de%20Labios/COMFOR%20BEAUTY%20-%20BÁLSAMO%20LABIAL%20FRUTAS%20NO.CX5053'
                l3='./Hidratante%20de%20Labios/VASEINA%20-%20ALOE%20VERA'
            />
            <ThreeItems
                title='Serum'
                link='./Serum?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/skincare/serum01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/skincare/serum05.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/skincare/serum07.jpg'
                p1={`HUDABEAUTY - SERUM EUK 134' 0.1 POR CIENTO NO.H63033`}
                p2={`HUDABEAUTY - 99 POR CIENTO VITAMIN C NO.H96001`}
                p3='HUDABEAUTY - ROSE NO.H96009'
                l1={`./Serum/HUDABEAUTY%20-%20SERUM%20EUK%20134'%200.1%20POR%20CIENTO%20NO.H63033`}
                l2={`./Serum/HUDABEAUTY%20-%2099%20POR%20CIENTO%20%20VITAMIN%20C%20NO.H96001`}
                l3='./Serum/HUDABEAUTY%20-%20ROSE%20NO.H96009'
            />
            <ThreeItems
                title='Jabon Facial'
                link='./Jabon%20Facial?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/foamFacial01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/foamFacial06.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/foamFacial03.jpg'
                p1='HUDABEAUTY - CLEANSING FOAM APPLE CIDER VINEGAR NO.H90072'
                p2='HUDABEAUTY - CLEANSING FOAM SUPER PROTECT DAILY WHITE NO.H90052'
                p3='HUDABEAUTY - CLEANSING FOAM SOFT SKIN NO.90044'
                l1='./Jabon%20Facial/HUDABEAUTY%20-%20CLEANSING%20FOAM%20APPLE%20CIDER%20VINEGAR'
                l2='./Jabon%20Facial/HUDABEAUTY%20-%20CLEANSING%20FOAM%20SUPER%20PROTECT%20DAILY%20WHITE'
                l3='./Jabon%20Facial/HUDABEAUTY%20-%20CLEANSING%20FOAM%20SOFT%20SKIN'
            />
            <ThreeItems
                title='MASCARILLAS'
                link='./Mascarillas?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fexfoliante01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fmask05.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fmask08.jpg'
                p1='MÁSCARA DE LABIOS DE NARANJA NO.8229'
                p2='HUDABEAUTY - MASCARILLA DE ARCILLA'
                p3='EVER ROSA - ALOE VERA NO.ER88-17023'
                l1='./Mascarillas/MÁSCARA%20DE%20LABIOS%20DE%20NARANJA%20NO.8229'
                l2='./Mascarillas/HUDABEAUTY%20-%20MASCARILLA%20DE%20ARCILLA'
                l3='./Mascarillas/EVER%20ROSA%20-%20ALOE%20VERA%20NO.ER88-17023'
            />
            <DoublePic
                prod1={{
                    title: 'EXFOLIANTES',
                    img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fbodycream01.jpg',
                    link: './Exfoliantes?page=1',
                }}
                prod2={{
                    title: 'GEL',
                    img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fgel02.jpg',
                    link: './Gel?page=1'
                }}
            />
            <DoublePic
                prod1={{
                    title: 'ESPUMA FACIAL',
                    img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fespuma04.jpg',
                    link: './Espuma%20Facial?page=1',
                }}
                prod2={{
                    title: 'PROTECTORES SOLAR',
                    img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products/comestico/protector06.jpg',
                    link: './Protectores%20Solar?page=1'
                }}
            />
            <DoublePic
                prod1={{
                    title: 'CONTORNO',
                    img: 'https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fskincare%2Fcontorno02.jpg',
                    link: './Contorno?page=1',
                }}
                prod2={{
                    title: 'MÁQUINA',
                    img: 'https://images.pexels.com/photos/4439444/pexels-photo-4439444.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                    link: './Máquina?page=1'
                }} />

        </div>
    )
}

/* 
    exfoliantes
    gel
    espuma facial
    protectores solar
    contorno
    maquina
*/

export default SkinCare