import React from 'react'
import './DoublePic.css'
import { Link } from 'react-router-dom'

const DoublePic = ({
    prod1 = { title: '', img: '', link: '' },
    prod2 = { title: '', img: '', link: '' } }) => {

    return (
        <div className='doublePic'>
            <div className="left box">
                <img className='box-img' src={prod1?.img || ''} alt={prod1?.title} />
                <p><b>{prod1?.title || 'title'}</b></p>
                <Link to={prod1?.link}>DESCUBRIB MAS ➜</Link>
            </div>
            <div className="right box">
                <img className='box-img' src={prod2?.img || ''} alt={prod2?.title} />
                <p><b>{prod2?.title || 'title'}</b></p>
                <Link to={prod2?.link}>DESCUBRIB MAS ➜</Link>
            </div>
        </div>
    )
}

export default DoublePic