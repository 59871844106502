import React from 'react'
import './Moreinmenu.css'
import useViewportSize from './useViewportSize'

const Moreinmenu = () => {

    const viewport = useViewportSize()

    return (
        <div className='Moreinmenu'>
            {viewport.width > 515 ?
                <h3>Más información en el menú</h3>
                :
                <h4>Más información en el menú</h4>}
        </div>
    )
}

export default Moreinmenu