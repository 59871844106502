import React from 'react'

function getViewportSize() {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
}

const useViewportSize = () => {
    const [viewportSize, setViewportSize] = React.useState(getViewportSize())

    React.useLayoutEffect(() => {
        const isWindow = typeof window !== 'undefined'
        function handleViewportResize() {
            setViewportSize(getViewportSize())
        }

        isWindow && window.addEventListener('resize', handleViewportResize)

        return () => {
            isWindow && window.removeEventListener('resize', handleViewportResize)
        }
    }, [viewportSize])

    return viewportSize
}

export default useViewportSize