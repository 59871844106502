import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { getProducts } from '../api/prorductsAPIs'
import './Product.css'

const Product = () => {
  const params = useLoaderData()
  const [product, setProduct] = useState({})

  //fetch product once
  useEffect(() => {
    const fetchProduct = async () => setProduct((await getProducts(params.product)).data);
    fetchProduct()
  }, [])

  if (!product) return (
    <div className='product'>
      <h1 className='product-title'>LOADING...</h1>
    </div>
  )

  return (
    <div className='product'>
      <img className='product-img' src={product.productImage} alt={product.productName} />
      <h3 className='product-title'>{product.productName}</h3>
      <p className="product-description">{product.productDescription || 'CARGANDO...'}</p>
    </div>
  )
}

export default Product