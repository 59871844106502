import React from 'react'
import "./Navbar.css"
import { Link } from 'react-router-dom'
import { getCategories } from '../api/categoriesAPIs'
import { getSubcategories } from '../api/subcategoriesAPIs'
import annylogo from '../assets/annylogojpg.jpeg'
import closeMenu from '../assets/close.svg'
import menuicon from '../assets/menuicon.svg'
import arrowright from '../assets/arrowright.svg'

const Navbar = () => {

    const [isHover, setisHover] = React.useState({
        hover: false,
        index: -1,
        current: null,
        smallWindow: false
    })
    const [categories, setCategories] = React.useState(null)
    const [subcategory, setSubcategory] = React.useState(null)
    const [sideBar, setSideBar] = React.useState(false)

    //listen for window size
    React.useLayoutEffect(() => {
        const handleWindowSize = () => {
            if (window.innerWidth <= 950) setisHover({ ...isHover, smallWindow: true })
            else setisHover({ ...isHover, smallWindow: false })
        }
        window.addEventListener("resize", handleWindowSize)

        //get inital window size on first render
        handleWindowSize()

        return () => window.removeEventListener('resize', handleWindowSize)
    }, [])

    const handleClickMenu = (key, current) => setisHover(() => {
        if (key !== isHover.index) return ({
            ...isHover, hover: true, index: key, current
        })
        return ({ ...isHover, hover: !isHover.hover })
    })
    const handleCloseMenu = () => setisHover({ ...isHover, hover: false })
    const handleSideBar = () => {
        let mainContainer = document.querySelector(".Home")
        if (isHover.smallWindow) {
            //mobile screen
            if (sideBar) {
                mainContainer.style.overflowY = 'auto'
            } else {
                mainContainer.style.overflowY = 'hidden'
            }
        } else {
            //pc screen
            if (sideBar) {
                mainContainer.style.overflowY = 'auto'
                mainContainer.style.paddingRight = '0px'
            } else {
                mainContainer.style.overflowY = 'hidden'
                mainContainer.style.paddingRight = '17px'
            }
        }
        setSideBar(!sideBar);
        setisHover({ ...isHover, index: -1 })
    }
    const handleArrowLeft = () => {
        //need optimazation HERE
        setisHover({ ...isHover, index: -1 })
        setSubcategory(null)
    }

    //fetch categories once
    React.useEffect(() => {
        async function fetchCategories() {
            const result = await getCategories()
            setCategories(result.data)
        }
        fetchCategories()
    }, [])
    //fetch subcategoires based on category
    React.useEffect(() => {
        async function fetchSubcategory(category) {
            const result = await getSubcategories(category)
            setSubcategory(result.data)
        }
        if (isHover.index !== -1) fetchSubcategory(categories[isHover.index]?.categoryName)
    }, [isHover.index])

    return (
        <div className="navbar">
            {/* NAVBAR */}
            {/* SMALL WINDOW MENU ICON */}
            <div className="smallwindow-menu">
                <img src={menuicon} alt="menu icon" onClick={handleSideBar} />
            </div>
            {/* LOGO */}
            <div className="logo">
                <Link to={`/`} onClick={() => sideBar() && handleSideBar()}>
                    <img src={annylogo} alt="anny logo" width={80} />
                </Link>
            </div>
            {/* NAVBAR */}
            <nav className={sideBar ? 'nav-show' : ''}>
                {/* CLOSE MENU ICON */}
                {isHover.smallWindow && <div className={`closeMenu ${isHover.smallWindow && 'nav-show'}`} >
                    <img src={closeMenu} alt="close menu" width={25}
                        onClick={handleSideBar}
                    />
                </div>}
                {/* NAVBAR CATEGORIES */}
                <ul>
                    {categories && categories.map((link, key) => {
                        return <li key={key}
                            style={!isHover.smallWindow && isHover.index === key ? { borderBottom: '2px solid black' } : {}}>
                            <b onClick={() => handleClickMenu(key, link.categoryName)}>
                                {link.categoryName}
                            </b>
                            {isHover.smallWindow &&
                                <Link to={'/c/' + link.categoryName} onClick={handleSideBar}>
                                    <img src={arrowright} alt={'link arrow'} width={20} />
                                </Link>}
                        </li>
                    })}
                </ul>
                {/* NOT SIDEBAR */}
                {!isHover.smallWindow && isHover.hover && isHover.index !== -1 &&
                    <div className="links">
                        <div className="closeMenu">
                            <img src={closeMenu} alt="close menu" width={25}
                                onClick={handleCloseMenu}
                            />
                        </div>
                        {subcategory?.length > 0 && !isHover.smallWindow &&
                            <div className='subcategory'>
                                <ul className='subcategory-lists'>
                                    {subcategory.map((link, key) => (
                                        <div key={key} className="subs">
                                            <Link to={link.url + '?page=1'}
                                                onClick={() => {
                                                    handleCloseMenu()
                                                    document.querySelector('.Home').scroll({ top: 0, left: 0, behavior: 'smooth' })
                                                }}
                                            >{link.title}
                                            </Link>
                                        </div>
                                    ))}
                                </ul>
                            </div>}
                    </div>}
                {/* SIDEBAR */}
                {isHover.smallWindow && isHover.index !== -1 &&
                    <div className={isHover.index !== -1 ? 'sidebar-subcategory sidebar-subcategory-show' : ''}>
                        <div className={'subcategory-nav'}>
                            <button className='arrow-left'
                                onClick={handleArrowLeft}>
                                <img src={arrowright} alt='arrow right' width={25} />
                            </button>
                            <Link
                                to={'/c/' + isHover.current + '?page=1'}
                                onClick={() => {
                                    handleSideBar()
                                    document.querySelector('.Home').scroll({ top: 0, left: 0, behavior: 'smooth' })
                                }}>
                                {isHover.current}
                            </Link>
                            <button onClick={handleSideBar}>
                                <img src={closeMenu} alt='close menu' width={25} />
                            </button>
                        </div>
                        <ul>
                            {subcategory?.map((sub, key) => {
                                return <li key={key}>
                                    <Link
                                        to={sub.url + '?page=1'} className='subcategory-link'
                                        onClick={() => {
                                            handleSideBar()
                                            document.querySelector('.Home').scroll({ top: 0, left: 0, behavior: 'smooth' })
                                        }}>
                                        {sub.title}
                                        <img src={arrowright} alt='arrow right' width={25} />
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>}
            </nav >
        </div >
    )
}

export default Navbar