import React from 'react'
import './Accesorios.css'
import ImageTitle from '../comps/ImageTitle'
import ThreeItems from '../comps/ThreeItems'
import Moreinmenu from '../comps/Moreinmenu'

const Accesorios = () => {
    return (
        <div className='Accesorios'>
            <ImageTitle
                title='Accesorios'
                source={'front'}
            />
            <ThreeItems
                title='Coleteros'
                link='./Coleteros?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/coletero09.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/coletero12.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/coletero01.jpg'
                p1='ANNY SELECTION - COLETERO DE PAÑO DE GASA NO.COA-2'
                p2='ANNY SELECTION - COLETERO CON CONEJO Y FLORES'
                p3='ANNY SELECTION - COLETERO CON CRISTALES Y BOLA DORADO'
                l1='./Coleteros/ANNY%20SELECTION%20-%20COLETERO%20DE%20PAÑO%20DE%20GASA%20NO.COA-2'
                l2='./Coleteros/ANNY%20SELECTION%20-%20COLETERO%20CON%20CONEJO%20Y%20FLORES'
                l3='./Coleteros/ANNY%20SELECTION%20-%20COLETERO%20CON%20CRISTALES%20Y%20BOLA%20DORADO'
            />
            <ThreeItems
                title='Aro de Cabello'
                link='./Aro%20de%20Cabello?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/aro01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/aro24.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/aro38.jpg'
                p1='ANNY SELECTION - ARO DE LANA NO.COA-18'
                p2='ANNY SELECTION - ARO DORADO CON PERLA NO.COA-9 4'
                p3='ANNY SELECTION - ARO CON CINTA DE ESTRELLA NO.COA-10'
                l1='./Aro%20de%20Cabello/ANNY%20SELECTION%20-%20ARO%20DE%20LANA%20NO.COA-18'
                l2='./Aro%20de%20Cabello/ANNY%20SELECTION%20-%20ARO%20DORADO%20CON%20PERLA%20NO.COA-9%204'
                l3='./Aro%20de%20Cabello/ANNY%20SELECTION%20-%20ARO%20CON%20CINTA%20DE%20ESTRELLA%20NO.COA-10'
            />
            <ThreeItems
                title='Pinche de Cabello'
                link='./Pinche%20de%20Cabello?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/pinche01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/pinche09.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/pinche15.jpg'
                p1='ANNY SELECTION - PINCHE DE PRINCESA BLANCA NIEVES NO.COA-32'
                p2='ANNY SELECTION - PINCHE DE FLORES CRISTAL CON PERLA NO.COA-25'
                p3='ANNY SELECTION - PINCHE DE PERLA NO.COA-21'
                l1='./Pinche%20de%20Cabello/ANNY%20SELECTION%20-%20PINCHE%20DE%20PRINCESA%20BLANCA%20NIEVES%20NO.COA-32'
                l2='./Pinche%20de%20Cabello/ANNY%20SELECTION%20-%20PINCHE%20DE%20FLORES%20CRISTAL%20CON%20PERLA%20NO.COA-25'
                l3='./Pinche%20de%20Cabello/ANNY%20SELECTION%20-%20PINCHE%20DE%20PERLA%20NO.COA-21'
            />
            <ThreeItems
                title='Peneita'
                link='./Peneita?page=1'
                img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/peneita01.jpg'
                img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/peneita03.jpg'
                img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/accessories/peneita05.jpg'
                p1='ANNY SELECTION - PENEITA CON PERLA NO.COA-33'
                p2='ANNY SELECTION - PENEITA CON PERLAS NO.COA-12 1'
                p3='ANNY SELECTION - PENEITA CON PERLAS NO.COA-12 3'
                l1='./Peneita/ANNY%20SELECTION%20-%20PENEITA%20CON%20PERLA%20NO.COA-33'
                l2='./Peneita/ANNY%20SELECTION%20-%20PENEITA%20CON%20PERLAS%20NO.COA-12%201'
                l3='./Peneita/ANNY%20SELECTION%20-%20PENEITA%20CON%20PERLAS%20NO.COA-12%203'
            />
        <Moreinmenu />
        </div>
    )
}

export default Accesorios