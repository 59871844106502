import { useState, useEffect } from 'react'
import './ProdsDisplay.css'
import { Link } from 'react-router-dom'
import filtericon from '../assets/filter.svg'
import closeicon from '../assets/close.svg'
import arrowright from '../assets/arrowright.svg'
import Searchsvg from '../assets/searchicon.svg'
import ReloadImage from './ReloadImage'

const ProdsDisplay = ({
    data, currentPage, count, keywords, getCounts,
    subcat, navigation, searchParams, setSearchParams }) => {

    const [isHover, setIsHover] = useState(false)
    const [isSideBar, setIsSideBar] = useState(false)
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [filteredCount, setFilteredCount] = useState(null)
    const [allKeywords, setAllKeywords] = useState(true)
    const [searchbarinput, setSearchbarinput] = useState('')

    const handleHover = (index) => {
        setIsHover(!isHover)
    }
    const handleSideBar = () => setIsSideBar(!isSideBar)
    const handleSelectedKeywords = (keyword) => {
        if (selectedKeywords.includes(keyword))
            setSelectedKeywords(selectedKeywords.filter(val => val !== keyword))
        else setSelectedKeywords([...selectedKeywords, keyword])
    }
    const handlePageChange = (page) => {
        let query = searchParams.getAll('query')
        setSearchParams({ page: page + 1, query })
        document.querySelector('.Home').scroll({ top: 0, left: 0, behavior: 'smooth' })
    }

    //submit button and handle sidebar
    const handleSubmitFilter = (e, dontclosemenu, selectsidebaronly) => {
        e.preventDefault()
        if (selectsidebaronly) {
            const removednonkeyword = selectedKeywords.filter(key => keywords.includes(key))
            let newquery = removednonkeyword[0]
            for (let i = 1; i < removednonkeyword.length; i++) {
                newquery += "%2C" + removednonkeyword[i]
            }
            setSearchbarinput('')
            navigation('?page=1&query=' + (newquery || ''))
        } else if (searchbarinput && searchbarinput.length > 0) {
            navigation('?page=1&query=' + searchbarinput)
            setAllKeywords(true)
        } else if (allKeywords) {
            navigation('?page=1')
        } else {
            let newquery = selectedKeywords[0]
            for (let i = 1; i < selectedKeywords.length; i++) {
                newquery += "%2C" + selectedKeywords[i]
            }
            navigation('?page=1&query=' + newquery)
        }
        if (dontclosemenu) return
        handleSideBar()
    }
    const handleResetFilter = () => {
        setAllKeywords(true)
        setSelectedKeywords([])
        //uncontrolled
        keywords.map(val => document.getElementById(val).checked = false)
        document.getElementById('all').checked = true
        //uncontrolled
    }
    const handleSearchBarChange = (e) => setSearchbarinput(e.target.value)
    const handleResetSearchBar = (e) => {
        setSearchbarinput('')
        searchParams.set('query', '')
    }

    //fetchcounts
    useEffect(() => {
        const fetchSetCounts = async () => {
            let res;
            if (allKeywords) res = await getCounts(subcat, '')
            else res = await getCounts(subcat, selectedKeywords)
            setFilteredCount(res.data)
        }
        //if all empty
        if (allKeywords === false && selectedKeywords.length === 0) setFilteredCount(0)
        else {
            let checkboxes = document.getElementsByClassName('box')
            for (let i = 0; i < checkboxes.length; i++) {
                if (selectedKeywords.includes(checkboxes[i].name)) {
                    checkboxes[i].checked = true
                }
            }
            if (allKeywords === false) document.getElementById('all').checked = false
            fetchSetCounts()
        }
    }, [selectedKeywords, allKeywords, getCounts, subcat])

    //update filter when refresh from url query
    useEffect(() => {
        console.log("nene", searchbarinput);
        let query = searchbarinput === '' ? searchParams.get('query') : searchbarinput
        if (query && query.length > 0) {
            if (searchbarinput && searchbarinput.length > 0) {
                setAllKeywords(true)
                setSelectedKeywords([searchbarinput])
                setSelectedKeywords([])
            } else {
                let splited = query.split(',')
                const filtered = splited.filter(split => !keywords.includes(split))
                setSearchbarinput(filtered[0] || '')
                setSelectedKeywords(splited)
                if (filtered[0]) setAllKeywords(true)
                else setAllKeywords(false)
            }
        }
    }, [])


    return (
        <div className='prodsDisplay'>
            <div className="filter" >
                <button onClick={handleSideBar}>
                    <img src={filtericon} alt='filter icon' width={'20'} />
                    Filtros
                </button>
                |
                <img src={Searchsvg} alt='Search' width={20} />
                <form onSubmit={handleSubmitFilter}>
                    <input type="text"
                        name="query"
                        placeholder={'Buscar...'}
                        value={searchbarinput}
                        onChange={handleSearchBarChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSubmitFilter(e, true)
                        }}
                    />
                    {searchbarinput !== '' && <button onClick={handleResetSearchBar}><img src={closeicon} alt="close icon" /></button>}
                </form>
            </div>
            <div className="count">{count === 0 ? '0 Producto' : count + " Productos"}</div>
            <div className='prods'>
                {data?.map((prod, key) => {
                    return <Link to={'./' + prod.productName} className={'card'} key={key}
                        onMouseEnter={() => handleHover(key)}
                    >
                        <ReloadImage
                            imgurl={prod.productImage}
                            className={'prodimg'}
                            productName={prod.productName}
                        />
                        <p className='title'>{prod.productBrand?.brandName && '-'}{prod.productName}</p>
                        <p className='descript'>{prod.productDescription}</p>
                        <p className='link'>Ver más <img className='arrowright' src={arrowright} alt="arrow right" /></p>
                    </Link>
                })}
            </div>
            <div className="pages">
                <ul>
                    {
                        //a button for each page， li > Link
                        [...Array.from(Array(Math.ceil(count / 9)).keys())].map((num, key) =>
                            <li key={key}>
                                <button onClick={() => handlePageChange(key)}>
                                    {parseInt(currentPage) === num + 1 ?
                                        <b style={{
                                            fontSize: '1.2rem',
                                            borderBottom: '1px solid black'
                                        }}>{num + 1}</b>
                                        : <>{num + 1}</>}
                                </button>
                            </li>)
                    }
                </ul>
            </div>
            {/* filter sidebar */}
            <div className={isSideBar ? 'bar bar-out' : 'bar'}>
                <button className='closebtn'><img src={closeicon} alt="close icon"
                    onClick={handleSideBar}
                /></button>
                <ul>
                    <li>
                        <button className='title'>
                            <span>MARCAS | TIPOS</span>
                            <span>|||</span>
                        </button>
                        <div className="collapseableInputs">
                            <form className='filter-form' onSubmit={(e) => handleSubmitFilter(e, false, true)}>
                                <div className='checkbox'>
                                    <input type="checkbox"
                                        id={'all'}
                                        name={'all'}
                                        value={'all'}
                                        onChange={() => setAllKeywords(!allKeywords)}
                                        checked={allKeywords} />
                                    <label htmlFor={'all'}>Todos</label>
                                </div>
                                {keywords.map((keyword, index) => {
                                    return <div key={index} className='checkbox'>
                                        <input type="checkbox"
                                            className='box'
                                            id={keywords[index]}
                                            name={keywords[index]}
                                            value={keywords[index]}
                                            checked={selectedKeywords.includes(keyword)}
                                            onChange={() => handleSelectedKeywords(keywords[index])} />
                                        <label htmlFor={keywords[index]}>{keywords[index]}</label>
                                    </div>
                                })}
                                <div className="opbtns">
                                    <button onClick={handleResetFilter}>REINICIAR</button>
                                    <input type='submit'
                                        value={filteredCount + ' RESULTADOS'} />
                                </div>
                            </form>
                        </div>
                    </li>
                    {/* <li>
                        <button className='title'>
                            MARCAS | TIPOS
                            <span>|arrow down|</span>
                        </button>
                    </li> */}
                </ul>

            </div>
            <div className={isSideBar ? 'cover' : 'cover cover-out'}
                onClick={handleSideBar}>
            </div>
        </div>
    )
}

export default ProdsDisplay