import axios from "./axios.js";

export const getAllSubcategories = () => axios.get('/subcategories')
export const getSubcategories = (category) => axios.get('/subcategories/cat/' + category)

export const createSubcategory = (subcactegory) => axios.post('/subcategories/m/create', subcactegory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})  
export const updateSubcategory = (subcactegory) => axios.post('/subcategories/m/update', subcactegory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})
export const deleteSubcategory = (subcactegory) => axios.post('/subcategories/m/delete', subcactegory, {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})
export const getSubcategoryKeywords = (title) => axios.get('/subcategories/keywords/'+title)