import React from 'react'
import PosterDescriptPic from '../comps/PosterDescriptPic'
import "./Perfumes.css"
import posterimg1 from '../assets/landing10.jpg'
import cremaperfume1 from '../assets/prods/perfumes/cremaperfume1.jpg'
import DoublePic from '../comps/DoublePic'
import arrowright from '../assets/arrowright.svg'
import Moreinmenu from '../comps/Moreinmenu'
import ImageTitle from '../comps/ImageTitle'
import ThreeItems from '../comps/ThreeItems'

//fully custtomize
const Perfumes = () => {

  const posterContent = [
    {
      title: 'PERFUME 35ML',
      descript: 'V.V LOVE - PARTY MAGIC',
      link: 'perfumes/Perfume 35mL?page=1'
    },
    {
      title: 'PERFUME 35ML',
      descript: 'V.V LOVE - HUGE BASICO',
      link: 'perfumes/Perfume 35mL?page=1'
    },
  ]
  const posterimg2 = "https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfumelong11.jpg"
  //cremaperfume1, cremaperfume2, cremaperfume3, cremaperfume4

  const twoKids = {
    prod1: {
      title: 'VIVA JACK - FLYING HERO NO.VL8032-9',
      img: "https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfumeChildren01.png",
      link: './Jóvenes?page=1'
    },
    prod2: {
      title: 'V.V LOVE - COMBO AMAZING RED NO.VL9065-39',
      img: "https://smartwhale.nyc3.digitaloceanspaces.com/products/perfumecremacombo/bodycremacombo01.jpg",
      link: './Set%20Perfume%20&%20Crema?page=1'
    }
  }

  return (
    <div className='perfumes'>
      <ImageTitle
        title='PERFUME'
        source='https://smartwhale.nyc3.digitaloceanspaces.com/products%2Fperfume%2Fadperfume01.webp'
      />
      <PosterDescriptPic content={posterContent[0]} img={posterimg1} reverse={true} />
      <PosterDescriptPic content={posterContent[1]} img={posterimg2} />
      <ThreeItems
        title='PERFUME 35ML'
        link='./Perfume%2035mL?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfumelong/perfumelong06.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfumelong/perfumelong05.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfumelong/perfumelong53.jpg'
        p1='V.V LOVE - 777 SVIP WILD NO.VL8043-18'
        p2='V.V LOVE - AMAZING PINK NO.VL9054-32'
        p3='V.V LOVE - CIQ AMARI KQUE NO.VL8043-9'
        l1='./Perfume%2035mL/V.V%20LOVE%20-%20777%20SVIP%20WILD%201'
        l2='./Perfume%2035mL/V.V%20LOVE%20-%20AMAZING%20PINK'
        l3='./Perfume%2035mL/V.V%20LOVE%20-%20CIQ%20AMARI%20KQUEA'
      />
      <ThreeItems
        title='PERFUME 100ML'
        link='./Perfume%20100mL?page=1'
        img1='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfumeshort08.jpg'
        img2='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfumeshort16.jpg'
        img3='https://smartwhale.nyc3.digitaloceanspaces.com/products/perfume/perfumeshort01.jpg'
        p1='V.V LOVE - PINK CHARMING VANILLA NO.VL8801-22'
        p2='V.V LOVE - SELF-DECEIVE NO.VL8801-7'
        p3='V.V LOVE - HOMME SPORT VL8801-38'
        l1='./Perfume%20100mL/PINK%20CHARMING%20VANILLA%201'
        l2='./Perfume%20100mL/SELF%20DECEIVE'
        l3='./Perfume%20100mL/HOMME%20SPORT%201'
      />
      <DoublePic prod1={twoKids.prod1} prod2={twoKids.prod2} />
      <Moreinmenu />
    </div>
  )
}

export default Perfumes