import React from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
    const { pathname } = useLocation()

    React.useEffect(() => {
        document.querySelector('.Home').scrollTo(0,0)
    }, [pathname])

}

export default ScrollToTop